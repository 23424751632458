import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Button, Form, Input, message, Popconfirm, Space, Table} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import queryString from 'query-string';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import Cart from '../../components/Cart';
import {
  OrderDeliveryForm,
  OrderInfoForm,
  OrderInvoiceForm,
  OrderPaymentMethodForm,
  TotalItemAmountForm,
} from '../../components/CartCofigForms';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_DISPLAY,
  INVOICE_SUBTYPE,
  INVOICE_SUBTYPE_DISPLAY,
  INVOICE_TYPE,
  INVOICE_TYPE_DISPLAY,
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_DISPLAY,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
  ORDER_UI_STATUS_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
  PROVIDER_DISPLAY,
} from '../../domain/Constants';
import * as Validate from '../../domain/Validate';
import {format} from '../../utils/date';
import moment from 'moment';
import ExcelOrderExportButton from '../../components/ExcelOrderExportButton';
import StaffEditDeliveryFormModal from '../../components/StaffEditDeliveryFormModal';
import StaffEditCartFormModal from '../../components/StaffEditCartFormModal';
import ModalContent from '../../components/ModalContent';
import GenericInput, {INPUT_TYPES} from '../../components/GenericAntdInput';

import {staffGetMembers} from '../../Api';

function _splitArrayIntoChunk(arr, chunkSize) {
  let chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    let _chunk = arr.slice(i, i + chunkSize);
    chunks.push(_chunk);
  }
  return chunks;
}

const SEARCH_FIELDS = ['buyer_phone', 'id'];
const PATH = URL_PATH.dashboardOrders;
const PAGE_SIZE = 50;
const uiSchema = {
  order_id: {
    'ui:readonly': true,
  },
  order_number: {
    'ui:readonly': true,
  },
  total: {
    'ui:readonly': true,
  },
  payment_status: {
    'ui:readonly': true,
  },
  invoice_state: {
    'ui:readonly': true,
  },
  invoice_data: {
    failReason: {
      'ui:readonly': true,
    },
  },
};

const ChangeInvoiceDataModal = (props) => {
  const {order, onDataUpdate} = props;
  const initialState = {
    invoice_type: order.invoice_type,
    invoice_subtype: order.invoice_subtype,
    gui_number: order.gui_number,
    mobile_vehicle_code: order.mobile_vehicle_code,
    citizen_personal_certificate_code: order.citizen_personal_certificate_code,
  };
  const [form] = Form.useForm();
  const [values, setValues] = useState(initialState);

  const getInputProps = useCallback(
    (field) => {
      return {
        invoice_type: {
          label: '發票種類',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '發票種類必須填寫'}],
          onChange: async (value) => {
            form.setFieldsValue({
              [field]: value,
            });
            if (value === INVOICE_TYPE.threeCopies) {
              form.setFieldsValue({
                invoice_subtype: INVOICE_SUBTYPE.memberCarrier,
              });
              setValues((prev) => ({
                ...prev,
                invoice_subtype: INVOICE_SUBTYPE.memberCarrier,
              }));
            }
          },
          type: INPUT_TYPES.select,
          options: [
            {
              value: INVOICE_TYPE.twoCopies,
              label: INVOICE_TYPE_DISPLAY[INVOICE_TYPE.twoCopies],
            },
            {
              value: INVOICE_TYPE.threeCopies,
              label: INVOICE_TYPE_DISPLAY[INVOICE_TYPE.threeCopies],
            },
          ],
          placeholder: '請選擇發票種類',
        },
        invoice_subtype: {
          label: '載具種類',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '載具種類必須填寫'}],
          onChange: async (value) => {
            form.setFieldsValue({
              [field]: value,
            });
          },
          type: INPUT_TYPES.select,
          options: [
            {
              value: INVOICE_SUBTYPE.memberCarrier,
              label: INVOICE_SUBTYPE_DISPLAY[INVOICE_SUBTYPE.memberCarrier],
            },
            {
              value: INVOICE_SUBTYPE.mobileCarrier,
              label: INVOICE_SUBTYPE_DISPLAY[INVOICE_SUBTYPE.mobileCarrier],
              disabled: values.invoice_type === INVOICE_TYPE.threeCopies,
            },
            {
              value: INVOICE_SUBTYPE.citizenCarrier,
              label: INVOICE_SUBTYPE_DISPLAY[INVOICE_SUBTYPE.citizenCarrier],
              disabled: values.invoice_type === INVOICE_TYPE.threeCopies,
            },
          ],
          placeholder: '請選擇載具種類',
        },
        gui_number: {
          label: '統一編號',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '統一編號必須填寫'},
            {
              pattern: '^[0-9]{8}$',
              message: '統一編號格式不正確',
            },
          ],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請填寫統一編號',
        },
        mobile_vehicle_code: {
          label: '手機條碼載具',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '手機條碼載具必須填寫'},
            {
              pattern: '^/[0-9A-Z+-.]{7}$',
              message: '手機條碼載具格式不正確',
            },
          ],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請填寫手機條碼載具',
        },
        citizen_personal_certificate_code: {
          label: '自然人憑證載具',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '自然人憑證載具'},
            {
              pattern: '^[a-zA-Z]{2}[0-9]{14}$',
              message: '自然人憑證載具格式不正確',
            },
          ],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請填寫自然人憑證載具',
        },
      }[field];
    },
    [form, values],
  );

  const commonItemConfig = {
    colon: false,
    labelAlign: 'left',
    style: {display: 'flex', flexDirection: 'column'},
  };

  const onSubmit = async (data) => {
    await onDataUpdate({
      ...order,
      ...data,
    });
    AppActions.setModal(null);
  };

  return (
    <ModalContent title="編輯訂單發票資訊">
      <Form
        form={form}
        initialValues={initialState}
        onValuesChange={(_beChangedField, _afterChangedStruct) => {
          setValues((prev) => ({
            ...prev,
            ..._afterChangedStruct,
          }));
        }}
        onFinish={onSubmit}>
        <GenericInput
          config={commonItemConfig}
          {...getInputProps('invoice_type')}
        />
        <GenericInput
          config={commonItemConfig}
          {...getInputProps('invoice_subtype')}
        />
        {values.invoice_type === INVOICE_TYPE.threeCopies && (
          <GenericInput
            config={commonItemConfig}
            {...getInputProps('gui_number')}
          />
        )}
        {values.invoice_type === INVOICE_TYPE.twoCopies &&
          values.invoice_subtype === INVOICE_SUBTYPE.mobileCarrier && (
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('mobile_vehicle_code')}
            />
          )}
        {values.invoice_type === INVOICE_TYPE.twoCopies &&
          values.invoice_subtype === INVOICE_SUBTYPE.citizenCarrier && (
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('citizen_personal_certificate_code')}
            />
          )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <small style={{color: '#f00', paddingRight: 10}}>
            * 請注意，更改資訊後請手動代傳發票 *
          </small>
          <Button htmlType="submit" type="danger">
            更改發票資訊
          </Button>
        </div>
      </Form>
    </ModalContent>
  );
};

function OrdersPage(props) {
  const [records, setRecords] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [invoiceIssuing, setInvoiceIssuing] = React.useState(false);
  const [invoiceVoiding, setInvoiceVoiding] = React.useState(false);
  const [invoiceGenerating, setInvoiceGenerating] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [globalFilters, setGlobalFilters] = React.useState({
    created_from: '',
    created_to: '',
    delivery_date_from: '',
    delivery_date_to: '',
  });
  const [pagination, setPagination] = React.useState({
    current: 1,
    total: 0,
    pageSize: PAGE_SIZE,
  });
  const [tableStateSnapshot, setTableStateSnapshot] = React.useState({});
  const [activeFilterConfigs, setActiveFilterConfigs] = React.useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const app = React.useContext(AppContext.Context);
  const params = queryString.parse(props.location.search);
  const selectedId = params.id;
  const [disabledEditGuiNum, setDisabledEditGuiNum] = useState(true);
  const [secretActionsVisible, setSecretActionsVisible] = useState(0);
  const timesOfSecretActionShown = 3;

  const schema = {
    title: '編輯訂單',
    type: 'object',
    required: ['id'],
    properties: {
      order_id: {type: 'string', title: '單據編號'},
      order_number: {type: 'string', title: '國泰訂單付款編號'},
      gui_number: {
        type: 'string',
        title: '統一編號',
        readOnly: disabledEditGuiNum,
      },
      total: {type: 'number', title: '金額 (NT)'},
      payment_status: {
        type: 'string',
        title: '付款狀態',
        default: PAYMENT_STATUS.failure,
        anyOf: [
          {
            type: 'string',
            enum: [PAYMENT_STATUS.success],
            title: PAYMENT_STATUS_DISPLAY.success,
          },
          {
            type: 'string',
            enum: [PAYMENT_STATUS.failure],
            title: PAYMENT_STATUS_DISPLAY.failure,
          },
          {
            type: 'string',
            enum: [PAYMENT_STATUS.waiting],
            title: PAYMENT_STATUS_DISPLAY.waiting,
          },
        ],
      },
      status: {
        type: 'string',
        title: '狀態',
        default: ORDER_STATUS.waiting,
        anyOf: [
          {
            type: 'string',
            enum: [ORDER_STATUS.waiting],
            title: ORDER_STATUS_DISPLAY.waiting,
          },
          {
            type: 'string',
            enum: [ORDER_STATUS.done],
            title:
              selectedRecord &&
              selectedRecord.logistic_type !== LOGISTICE_TYPE.selfPick
                ? ORDER_UI_STATUS_DISPLAY.doneIcat
                : ORDER_UI_STATUS_DISPLAY.doneSelfPick,
          },
          {
            type: 'string',
            enum: [ORDER_STATUS.cancelled],
            title: ORDER_STATUS_DISPLAY.cancelled,
          },
        ],
      },
      ...(selectedRecord &&
      selectedRecord.logistic_type !== LOGISTICE_TYPE.selfPick &&
      selectedRecord.status === ORDER_STATUS.done
        ? {
            logistic_order_id: {
              type: 'string',
              title: '物流單號',
              defualt: '',
            },
          }
        : {}),
      staff_note: {
        type: 'string',
        title: '員工備註',
        default: '',
      },
      invoice_state: {
        type: 'string',
        title: '發票狀態',
        default: INVOICE_STATUS.waiting,
        anyOf: [
          {
            type: 'string',
            enum: [INVOICE_STATUS.waiting],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.waiting],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.issued],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.issued],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.voided],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.voided],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.issueFail],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.issueFail],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.voidFail],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.voidFail],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.generated],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.generated],
          },
          {
            type: 'string',
            enum: [INVOICE_STATUS.generate_fail],
            title: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.generate_fail],
          },
        ],
      },
    },
    dependencies: {
      // status: {
      //   oneOf: [
      //     {
      //       properties: {
      //         status: {
      //           enum: [ORDER_STATUS.done],
      //         },
      //         logistic_order_id: {
      //           type: 'string',
      //           title: '物流單號',
      //           defualt: '',
      //         },
      //       },
      //     },
      //     {
      //       properties: {
      //         status: {
      //           enum: [ORDER_STATUS.waiting],
      //         },
      //       },
      //     },
      //     {
      //       properties: {
      //         status: {
      //           enum: [ORDER_STATUS.cancelled],
      //         },
      //       },
      //     },
      //   ],
      // },
      invoice_state: {
        oneOf: [
          {
            properties: {
              invoice_state: {
                enum: [INVOICE_STATUS.issued],
              },
              invoice_data: {
                title: '',
                type: 'object',
                properties: {
                  invoiceNumber: {
                    readOnly: true,
                    type: 'string',
                    title: '發票號碼',
                    default: '',
                  },
                },
              },
            },
          },
          {
            properties: {
              invoice_state: {
                enum: [INVOICE_STATUS.issueFail, INVOICE_STATUS.voidFail],
              },
              invoice_data: {
                title: '',
                type: 'object',
                properties: {
                  failReason: {
                    readOnly: true,
                    type: 'string',
                    title: '發票開立/作廢失敗原因',
                    default: '',
                  },
                },
              },
            },
          },
          {
            properties: {
              invoice_state: {
                enum: [INVOICE_STATUS.voided, INVOICE_STATUS.waiting],
              },
            },
          },
        ],
      },
    },
  };

  const tableColumns = [
    {
      fixed: 'left',
      title: '單據編號',
      dataIndex: 'order_id',
      key: 'order_id',
      filteredValue: tableStateSnapshot.filters?.order_id || null,
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={`L11007300001`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button onClick={clearFilters} size="small" style={{width: 90}}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}>
              搜尋
            </Button>
          </Space>
        </div>
      ),
    },
    {
      fixed: 'left',
      title: '國泰訂單付款編號',
      dataIndex: 'order_number',
      key: 'order_number',
      width: 200,
      filteredValue: tableStateSnapshot.filters?.order_number || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={`20220110121900081788`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button onClick={clearFilters} size="small" style={{width: 90}}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                console.log(selectedKeys, confirm, 'id');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}>
              搜尋
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: 'ERP 回傳狀態',
      dataIndex: 'erp_result',
      width: 150,
      key: 'erp_result',
      filteredValue: tableStateSnapshot.filters?.erp_result || null,
      filters: [
        {
          text: '異常',
          value: 'fail',
        },
      ],
      render: (_, record, idx) => {
        return record.erp_result?.result === 'fail' ? '異常' : '-';
      },
    },
    {
      title: '金額',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: (_, record, idx) => {
        return `NT ${record.total}`;
      },
    },
    {
      title: '購買人手機',
      dataIndex: 'buyer_phone',
      key: 'buyer_phone',
      filteredValue: tableStateSnapshot.filters?.buyer_phone || null,
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={`0912345678`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button onClick={clearFilters} size="small" style={{width: 90}}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                console.log(selectedKeys, confirm, 'phone');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}>
              搜尋
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '付款狀態',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: 150,
      render: (_, record, idx) => {
        return PAYMENT_STATUS_DISPLAY[record.payment_status];
      },
      filteredValue: tableStateSnapshot.filters?.payment_status || null,
      filters: [
        {
          text: PAYMENT_STATUS_DISPLAY.success,
          value: PAYMENT_STATUS.success,
        },
        {
          text: PAYMENT_STATUS_DISPLAY.failure,
          value: PAYMENT_STATUS.failure,
        },
        {
          text: PAYMENT_STATUS_DISPLAY.waiting,
          value: PAYMENT_STATUS.waiting,
        },
      ],
    },
    {
      title: '發票格式',
      dataIndex: 'invoice_type',
      width: 120,
      render: (_, record, idx) => {
        return INVOICE_TYPE_DISPLAY[record.invoice_type] || '-';
      },
      filteredValue: tableStateSnapshot.filters?.invoice_type || null,
      filters: [
        {
          text: INVOICE_TYPE_DISPLAY[INVOICE_TYPE.twoCopies],
          value: INVOICE_TYPE.twoCopies,
        },
        {
          text: INVOICE_TYPE_DISPLAY[INVOICE_TYPE.threeCopies],
          value: INVOICE_TYPE.threeCopies,
        },
      ],
    },
    {
      title: '發票狀態',
      dataIndex: 'invoice_state',
      key: 'invoice_state',
      width: 120,
      render: (_, record, idx) => {
        return INVOICE_STATUS_DISPLAY[record.invoice_state] || '-';
      },
      filteredValue: tableStateSnapshot.filters?.invoice_state || null,
      filters: [
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.waiting],
          value: INVOICE_STATUS.waiting,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.issued],
          value: INVOICE_STATUS.issued,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.issueFail],
          value: INVOICE_STATUS.issueFail,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.voided],
          value: INVOICE_STATUS.voided,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.voidFail],
          value: INVOICE_STATUS.voidFail,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.generated],
          value: INVOICE_STATUS.generated,
        },
        {
          text: INVOICE_STATUS_DISPLAY[INVOICE_STATUS.generate_fail],
          value: INVOICE_STATUS.generate_fail,
        },
      ],
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (_, record, idx) => {
        return ORDER_STATUS_DISPLAY[record.status] || '-';
      },
      filteredValue: tableStateSnapshot.filters?.status || null,
      filters: [
        {
          text: ORDER_STATUS_DISPLAY[ORDER_STATUS.waiting],
          value: ORDER_STATUS.waiting,
        },
        {
          text: ORDER_STATUS_DISPLAY[ORDER_STATUS.done],
          value: ORDER_STATUS.done,
        },
        {
          text: ORDER_STATUS_DISPLAY[ORDER_STATUS.cancelled],
          value: ORDER_STATUS.cancelled,
        },
      ],
    },
    {
      title: '取貨方式',
      dataIndex: 'logistic_type',
      key: 'logistic_type',
      width: 120,
      render: (_, record, idx) => {
        return LOGISTICE_TYPE_DISPLAY[record.logistic_type] || '-';
      },
      filteredValue: tableStateSnapshot.filters?.logistic_type || null,
      filters: [
        {
          text: LOGISTICE_TYPE_DISPLAY[LOGISTICE_TYPE.selfPick],
          value: LOGISTICE_TYPE.selfPick,
        },
        {
          text: LOGISTICE_TYPE_DISPLAY[LOGISTICE_TYPE.icatFreeze],
          value: LOGISTICE_TYPE.icatFreeze,
        },
        {
          text: LOGISTICE_TYPE_DISPLAY[LOGISTICE_TYPE.icatFridge],
          value: LOGISTICE_TYPE.icatFridge,
        },
        {
          text: LOGISTICE_TYPE_DISPLAY[LOGISTICE_TYPE.icatNormal],
          value: LOGISTICE_TYPE.icatNormal,
        },
      ],
    },
    {
      title: '登入方式',
      dataIndex: 'provider',
      key: 'provider',
      width: 120,
      render: (_, record, idx) => {
        return PROVIDER_DISPLAY[record.provider]?.label || '查無此會員';
      },
    },
    {
      title: '訂單成立時間',
      dataIndex: 'created',
      key: 'created',
      width: 150,
      render: (_, record, idx) => {
        return format(record.created, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'created' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.created - b.created,
      // sortDirections: ['descend'],
    },
    {
      title: '取貨/到貨日',
      dataIndex: 'delivery_date.$date',
      key: 'delivery_date.$date',
      width: 150,
      render: (_, record, idx) => {
        return format(record.delivery_date.$date, 'YYYY-MM-DD');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'delivery_date.$date' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.delivery_date.$date - b.delivery_date.$date,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={() => {
              navigate(`${PATH}?id=${record.id}`);
            }}>
            編輯
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      if (selectedId) {
        try {
          setSelectedRecord(await app.actions.getOrderById(selectedId));
        } catch (ex) {
          console.warn(ex);
        }
      } else {
        setSelectedRecord(null);
        await _updateOrderList();
      }

      AppActions.setLoading(false);
    }

    if (app.staffUser) {
      fetchData();
    }
  }, [app.actions, selectedId, app.staffUser]);

  const _updateOrderList = React.useCallback(
    async (configs = null) => {
      setIsLoading(true);
      try {
        let _configs = configs ||
          activeFilterConfigs || {
            query: {
              total: {$gte: 0},
            },
            paging: {
              offset: 0,
              limit: PAGE_SIZE,
            },
            sorting: ['-created'],
          };
        let _resp = await app.actions.getOrders(_configs);
        let _orders = _resp.results;
        setPagination({
          total: _resp.total,
          pageSize: PAGE_SIZE,
          current: _configs.paging.offset / PAGE_SIZE + 1,
        });

        const _users = await staffGetMembers({
          query: {
            owner: {
              $in: _orders.map((o) => o.owner),
            },
          },
        });
        const results = _orders.map((o) => {
          const targetUser = _users.find((u) => u.owner === o.owner);
          return {
            ...o,
            provider: targetUser?.provider || 'unknow',
          };
        });
        setRecords(results);
        return results;
      } catch (ex) {
        console.warn(ex);
      } finally {
        setIsLoading(false);
      }
    },
    [app.actions, activeFilterConfigs],
  );

  const _refreshOrder = React.useCallback(
    async (orderId) => {
      try {
        AppActions.setLoading(true);
        let _updatedOrder = await app.actions.getOrderById(orderId);
        setSelectedRecord(_updatedOrder);
      } catch (ex) {
        console.warn(ex);
      } finally {
        AppActions.setLoading(false);
      }
    },
    [app.actions],
  );

  const _onDataUpdate = React.useCallback(
    async (formData) => {
      AppActions.setLoading(true);
      let _result = false;
      try {
        let _resp = await app.actions.staffUpdateOrder(formData);
        setSelectedRecord(_resp);
        _result = true;
      } catch (err) {
        //
        _result = false;
      }
      AppActions.setLoading(false);

      _result ? message.success('資料已更新') : message.error('資料更新失敗');
    },
    [app.actions],
  );

  function _getGlobalFilterQueryConfig(query, globalFilters) {
    const _query = {
      $and: [],
      ...query,
    };

    const queryConfigRange = [];

    if (
      (globalFilters.created_from || globalFilters.created_to) &&
      (!globalFilters.created_from || !globalFilters.created_to)
    ) {
      throw '日期區間起始與結束為兩者皆必填！';
    }

    if (
      (globalFilters.delivery_date_from || globalFilters.delivery_date_to) &&
      (!globalFilters.delivery_date_from || !globalFilters.delivery_date_to)
    ) {
      throw '日期區間起始與結束為兩者皆必填！';
    }

    if (globalFilters.created_from && globalFilters.created_to) {
      if (
        !Validate.validDateString(globalFilters.created_from) ||
        !Validate.validDateString(globalFilters.created_to)
      ) {
        throw '搜尋之訂單日期格式不正確！';
      }

      queryConfigRange.push(['created_from', 'created_to']);
    }

    if (globalFilters.delivery_date_from && globalFilters.delivery_date_to) {
      if (
        !Validate.validDateString(globalFilters.delivery_date_from) ||
        !Validate.validDateString(globalFilters.delivery_date_to)
      ) {
        throw '搜尋之到/取貨日期格式不正確！';
      }

      queryConfigRange.push(['delivery_date_from', 'delivery_date_to']);
    }

    const _dataKeyMaps = {
      created_from: 'created',
      created_to: 'created',
      delivery_date_from: 'delivery_date',
      delivery_date_to: 'delivery_date',
    };

    if (_query?.$and?.length > 0) {
      _query.$and = _query.$and.filter(
        (
          q, // filter out delivery and created
        ) => !q.created && !q.delivery_date,
      );
    } else {
      _query.$and = [];
    }

    for (let [from, to] of queryConfigRange) {
      let _from = moment(globalFilters[from], 'YYYY-MM-DD').valueOf();
      let _to = moment(globalFilters[to], 'YYYY-MM-DD')
        .add(1, 'days')
        .valueOf();

      if (from.indexOf('delivery_date') === 0) {
        _query.$and.push(
          ...[
            {delivery_date: {$gte: {$date: _from}}},
            {delivery_date: {$lte: {$date: _to}}},
          ],
        );
      } else {
        _query.$and.push(
          ...[
            {
              [_dataKeyMaps[from]]: {$gte: _from},
            },
            {
              [_dataKeyMaps[to]]: {$lte: _to},
            },
          ],
        );
      }
    }

    if (!_query.$and || _query.$and.length === 0) {
      delete _query.$and;
    }
    return _query;
  }

  const _onTableChange = React.useCallback(
    async (
      pagination, // {current: 1, pageSize: 3}
      filters,
      sorter,
      // currentDataSource: [], action: paginate | sort | filter
      extra,
    ) => {
      let _configs = {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {},
        sorting: ['-created'],
      };

      try {
        _configs.query = _getGlobalFilterQueryConfig(
          _configs.query,
          globalFilters,
        );
      } catch (err) {
        message.error(err);
        return;
      }

      let _queries = [];
      for (let key of Object.keys(filters || {})) {
        if (filters[key] && filters[key].length > 0) {
          if (SEARCH_FIELDS.indexOf(key) !== -1) {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: {$regex: `.*${v}.*`}})),
            };
            _queries.push(_resultTypeQuery);
          } else if (key === 'erp_result') {
            let _erpQueryKey = 'erp_result.result';
            if (filters[key].indexOf('fail') !== -1) {
              let _resultTypeQuery = {
                [_erpQueryKey]: 'fail',
              };
              _queries.push(_resultTypeQuery);
            }
          } else {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: v})),
            };
            _queries.push(_resultTypeQuery);
          }
        }
      }

      if (_queries.length > 0) {
        if (_configs.query.$and) {
          _configs.query.$and.push(..._queries);
        } else {
          _configs.query.$and = _queries;
        }
      }

      if (extra.action === 'paginate') {
        _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
      }
      if (sorter && sorter.field && sorter.order) {
        if (sorter.field === 'delivery_date.$date') {
          _configs.sorting = [
            `${sorter.order === 'ascend' ? '' : '-'}delivery_date`,
          ];
        } else {
          _configs.sorting = [
            `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
          ];
        }
      }
      AppActions.setLoading(true);
      setActiveFilterConfigs(_configs);
      setTableStateSnapshot({
        pagination,
        filters,
        sorter,
      });
      const _orders = await _updateOrderList(_configs);

      if (
        filters.status?.length === 1 &&
        filters.status?.[0] === ORDER_STATUS.waiting
      ) {
        setSelectedRowKeys(_orders.map((r) => r.id));
      } else {
        setSelectedRowKeys([]);
      }

      AppActions.setLoading(false);
    },
    [_updateOrderList, globalFilters],
  );

  const _onApplyGlobalFilter = React.useCallback(async () => {
    try {
      let _configs = activeFilterConfigs || {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {},
        sorting: ['-created'],
      };

      try {
        _configs.query = _getGlobalFilterQueryConfig(
          _configs.query,
          globalFilters,
        );
      } catch (err) {
        message.error(err);
        return;
      }
      setActiveFilterConfigs(_configs);
      AppActions.setLoading(true);
      await _updateOrderList(_configs);
    } catch (err) {
      //
      console.log(err);
      message.error('無法取得訂單資料');
    }
    AppActions.setLoading(false);
  }, [globalFilters]);

  const _onInvoiceIssue = React.useCallback(async () => {
    try {
      setInvoiceIssuing(true);
      let _resp = await app.actions.staffIssueInvoice(selectedRecord.order_id); // order_id: L11009070003
      if (_resp.invoice_state !== INVOICE_STATUS.issued) {
        throw 'issue fail';
      }
      message.success('發票已開立');
      const _updatedOrder = await app.actions.getOrderById(
        selectedRecord.order_id,
      );
      const _latestOrder = await app.actions.staffUpdateOrder(_updatedOrder);
      setSelectedRecord(_latestOrder);
    } catch (err) {
      console.log('err', err);
      message.error('發票開立失敗');
    } finally {
      setInvoiceIssuing(false);
    }
  }, [app.actions, selectedRecord]);

  const _onInvoiceVoid = React.useCallback(async () => {
    try {
      setInvoiceVoiding(true);
      let _resp = await app.actions.staffVoidInvoice(selectedRecord.order_id);
      if (_resp.invoice_state !== INVOICE_STATUS.voided) {
        throw 'void fail';
      }
      message.success('發票已作廢');
    } catch (err) {
      console.log(err);
      message.error('作廢發票失敗');
    } finally {
      await _refreshOrder(selectedRecord.id);
      setInvoiceVoiding(false);
    }
  }, [app.actions, selectedRecord, _refreshOrder]);

  const _onRowSelect = React.useCallback((_selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(_selectedRowKeys);
  }, []);

  const _onUpdateErpOrders = React.useCallback(async () => {
    const hide = message.loading('正在更新訂單至 ERP...');
    try {
      // clean up id to update
      const ids = selectedRowKeys
        .map((id) => {
          let found = records.find((r) => r.id === id);
          if (found.erp_result?.result !== 'fail') {
            return null;
          }
          return found.order_id;
        })
        .filter((id) => !!id);

      // split ids as 3 elements for each api call
      let chunkIds = _splitArrayIntoChunk(ids, 3);
      let failIds = [];
      for (let units of chunkIds) {
        try {
          await app.actions.staffUpdateOrderToErp({
            order_ids: units,
          });
        } catch (err) {
          failIds.push(...units);
        }
      }

      setSelectedRowKeys([]);
      if (failIds.length !== 0) {
        message.warn('部分訂單更新失敗');
      } else {
        message.success('狀態已更新');
      }
    } catch (err) {
      //
      message.error('無法更新訂單！');
    } finally {
      await _updateOrderList();
      hide();
    }
  }, [_updateOrderList, app.actions, selectedRowKeys]);

  const _onMultiUpdate = React.useCallback(async () => {
    try {
      const ids = selectedRowKeys.map(
        (id) => records.find((r) => r.id === id).order_id,
      );
      let data = {
        ids: ids,
        status: ORDER_STATUS.done,
      };
      await app.actions.staffMultiUpdateOrder(data);
      await _updateOrderList();
      setSelectedRowKeys([]);
      message.success('狀態已更新');
    } catch (err) {
      //
      message.error('無法更新狀態！');
    }
  }, [_updateOrderList, app.actions, selectedRowKeys]);

  const _onStaffEditCartData = React.useCallback(async () => {
    const _onConfirm = async (data) => {
      try {
        const hide = message.loading('更新資料中...');
        let _resp = await app.actions.staffUpdateOrder({
          ...selectedRecord,
          ...data,
        });
        setSelectedRecord(_resp);
        hide();
        message.success('到貨/自取日已更新');
        AppActions.setModal(null);
      } catch (err) {
        message.error('無法更新到貨/自取日');
      }
    };

    try {
      AppActions.setModal(
        <StaffEditCartFormModal
          order={selectedRecord}
          onSubmit={_onConfirm}
          onClose={() => AppActions.setModal(null)}
        />,
      );
    } catch (err) {
      //
    }
  }, [selectedRecord, app.actions]);

  const _onStaffEditDeliveryInfo = React.useCallback(async () => {
    const _onConfirm = async (data) => {
      try {
        const hide = message.loading('更新資料中...');
        let _resp = await app.actions.staffUpdateOrder({
          ...selectedRecord,
          ...data,
        });
        setSelectedRecord(_resp);
        hide();
        message.success('宅配收件人資訊已更新');
        AppActions.setModal(null);
      } catch (err) {
        message.error('無法更新宅配收件人資訊');
      }
    };

    try {
      AppActions.setModal(
        <StaffEditDeliveryFormModal
          order={selectedRecord}
          onSubmit={_onConfirm}
          onClose={() => AppActions.setModal(null)}
        />,
      );
    } catch (err) {
      //
    }
  }, [selectedRecord, app.actions]);

  let _onEditGuiNumber = async () => {
    if (disabledEditGuiNum) {
      setDisabledEditGuiNum(false);
    } else {
      AppActions.setLoading(true);
      try {
        setDisabledEditGuiNum(true);
        const _order = await app.actions.getOrderById(selectedId);
        await _onDataUpdate({
          ..._order,
          gui_number: selectedRecord.gui_number,
        });
      } catch (e) {
        message.error('更改統編失敗');
      } finally {
        AppActions.setLoading(false);
      }
    }
  };

  if (!selectedRecord) {
    return (
      <Wrapper>
        <h2>訂單列表</h2>
        <div className="filters">
          <div className="inputs">
            <div className="item">
              <label>訂單成立起始日</label>
              <Input
                placeholder={'YYYY-MM-DD'}
                onChange={(e) =>
                  setGlobalFilters({
                    ...globalFilters,
                    created_from: e.target.value,
                  })
                }
                value={globalFilters.created_from}
              />
            </div>
            <div className="item">
              <label>訂單成立結束日</label>
              <Input
                placeholder={'YYYY-MM-DD'}
                onChange={(e) =>
                  setGlobalFilters({
                    ...globalFilters,
                    created_to: e.target.value,
                  })
                }
                value={globalFilters.created_to}
              />
            </div>
          </div>

          <div className="inputs">
            <div className="item">
              <label>取/到貨起始日</label>
              <Input
                placeholder={'YYYY-MM-DD'}
                onChange={(e) =>
                  setGlobalFilters({
                    ...globalFilters,
                    delivery_date_from: e.target.value,
                  })
                }
                value={globalFilters.delivery_date_from}
              />
            </div>
            <div className="item">
              <label>取/到貨結束日</label>
              <Input
                placeholder={'YYYY-MM-DD'}
                onChange={(e) =>
                  setGlobalFilters({
                    ...globalFilters,
                    delivery_date_to: e.target.value,
                  })
                }
                value={globalFilters.delivery_date_to}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              disabled={selectedRowKeys.length === 0}
              onClick={_onUpdateErpOrders}
              style={{marginRight: 10}}>
              更新 ERP 異常訂單
            </Button>
            <Button
              disabled={selectedRowKeys.length === 0}
              onClick={_onMultiUpdate}
              style={{marginRight: 10}}>
              標記訂單狀態為已完成
            </Button>
            <ExcelOrderExportButton
              dateRange={{
                from: globalFilters.created_from,
                to: globalFilters.created_to,
              }}
              filterConfigs={activeFilterConfigs}
              style={{marginRight: 10}}
            />
            <Button onClick={_onApplyGlobalFilter}>搜尋</Button>
          </div>
          <div className="note">
            * 說明：訂單下載範圍包含所有符合當前篩選範圍的資料
          </div>
          <div className="note">訂單總數：{pagination.total} 筆</div>
        </div>

        <Table
          locale={{
            triggerDesc: '排序遞減',
            triggerAsc: '排序遞增',
            cancelSort: '取消排序',
          }}
          // expandable={{
          //   expandedRowRender: (record) => {
          //     return (
          //       <div>
          //         {/*{record.items.map((i, index) => (*/}
          //         {/*  <div key={index} style={{display: 'flex'}}>*/}
          //         {/*    <div style={{minWidth: 250}}>{i.name}</div>{' '}*/}
          //         {/*    <div style={{minWidth: 100}}>{i.spec.name}</div>x{' '}*/}
          //         {/*    <div>{i.config.qty}</div>*/}
          //         {/*  </div>*/}
          //         {/*))}*/}
          //       </div>
          //     );
          //   },
          // }}
          rowSelection={{
            selectedRowKeys,
            onChange: _onRowSelect,
          }}
          loading={isLoading}
          dataSource={records}
          columns={tableColumns}
          rowKey={(record) => record.id}
          onChange={_onTableChange}
          pagination={{...pagination, showSizeChanger: false}}
          scroll={{x: 1300}}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              onClick={() => {
                setSelectedRecord(null);
                setSecretActionsVisible(0);
                navigate(PATH);
              }}>
              返回
            </Button>

            <Button
              style={{
                marginLeft: 10,
              }}
              onClick={_onStaffEditCartData}>
              編輯到貨/取貨日
            </Button>

            {[INVOICE_STATUS.waiting, INVOICE_STATUS.issueFail].indexOf(
              selectedRecord.invoice_state,
            ) !== -1 && (
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#52c41a',
                  color: '#fff',
                }}
                loading={invoiceIssuing}
                onClick={_onInvoiceIssue}>
                開立發票
              </Button>
            )}

            {[INVOICE_STATUS.issueFail].indexOf(
              selectedRecord.invoice_state,
            ) !== -1 &&
              [INVOICE_TYPE.threeCopies].indexOf(
                selectedRecord.invoice_type,
              ) !== -1 && (
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#52c41a',
                    color: '#fff',
                  }}
                  loading={false}
                  onClick={_onEditGuiNumber}>
                  {disabledEditGuiNum ? '編輯統編' : '確定更改'}
                </Button>
              )}

            {[INVOICE_STATUS.issued, INVOICE_STATUS.voidFail].indexOf(
              selectedRecord.invoice_state,
            ) !== -1 && (
              <Popconfirm
                title="確定要作廢此發票嗎？"
                onConfirm={async () => {
                  await _onInvoiceVoid();
                }}
                onCancel={() => {}}
                okButtonProps={{
                  type: 'danger',
                }}
                okText="確定"
                cancelText="取消">
                <Button
                  type="danger"
                  style={{marginLeft: 10}}
                  loading={invoiceVoiding}>
                  作廢發票
                </Button>
              </Popconfirm>
            )}

            {secretActionsVisible >= timesOfSecretActionShown && (
              <Popconfirm
                title="確定要代傳此發票嗎？需先請關貿註銷此發票才可使用此功能。"
                onConfirm={async () => {
                  try {
                    setInvoiceGenerating(true);
                    const resp = await app.actions.staffGenerateInvoice(
                      selectedRecord,
                    );
                    if (resp.invoice_state === INVOICE_STATUS.generate_fail) {
                      await _refreshOrder(selectedRecord.id);
                      throw new Error('generate_fail');
                    }
                    setSecretActionsVisible(0);
                    await _refreshOrder(selectedRecord.id);
                    message.success('代傳發票成功');
                  } catch (e) {
                    message.error('代傳發票失敗');
                  } finally {
                    setInvoiceGenerating(false);
                  }
                }}
                onCancel={() => {}}
                okButtonProps={{
                  type: 'danger',
                }}
                okText="確定"
                cancelText="取消">
                <Button
                  type="danger"
                  style={{marginLeft: 10}}
                  loading={invoiceGenerating}>
                  代傳發票
                </Button>
              </Popconfirm>
            )}

            {secretActionsVisible >= timesOfSecretActionShown && (
              <Popconfirm
                title="確定要更改發票資訊嗎？需先請關貿註銷此發票才可使用此功能。"
                onConfirm={() => {
                  AppActions.setModal(
                    <ChangeInvoiceDataModal
                      order={selectedRecord}
                      onDataUpdate={_onDataUpdate}
                    />,
                  );
                }}
                onCancel={() => 0}
                okButtonProps={{type: 'danger'}}
                okText="確定"
                cancelText="取消">
                <Button type="primary" style={{marginLeft: 10}}>
                  更改發票資訊
                </Button>
              </Popconfirm>
            )}
          </div>
          <div
            style={{
              opacity: 0,
              width: 80,
              height: 50,
              cursor: 'not-allowed',
            }}
            onClick={() => {
              setSecretActionsVisible((prev) => prev + 1);
            }}
          />
        </div>

        <JStorageForm
          schema={schema}
          uiSchema={uiSchema}
          instance={selectedRecord}
          onSubmit={_onDataUpdate}
          updateValue={(formData) => {
            setSelectedRecord(formData);
          }}
          submitText="更新 ERP"
        />
        <div className="order-detail">
          <Cart cart={selectedRecord} />
          <div className="form">
            <OrderPaymentMethodForm order={selectedRecord} />
          </div>
          <div className="form">
            <OrderInfoForm order={selectedRecord} />
          </div>
          <div className="form">
            <OrderDeliveryForm
              order={selectedRecord}
              onStaffEdit={_onStaffEditDeliveryInfo}
            />
          </div>
          <div className="form">
            <OrderInvoiceForm order={selectedRecord} />
          </div>
          <div className="form">
            <TotalItemAmountForm cart={selectedRecord} />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px;

  & > .filters {
    padding: 20px 0px;
    & > .note {
      font-size: 14px;
      color: grey;
      text-align: right;
    }
    & > .inputs {
      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & label {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  & .order-detail {
    & .form {
      margin-top: 20px;
    }
  }
`;

export default OrdersPage;
