import Form from 'antd/lib/form/Form';
import React from 'react';
import styled from 'styled-components';
import {Button as AntButton, message} from 'antd';
import ModalContent from './ModalContent';
import {isNotEmpty} from '../domain/FormatValidator';
import TextInput from './TextInput';
import {format} from '../utils/date';
import moment from 'moment';
import {validDateString} from '../domain/Validate';

function StaffEditCartFormModal(props) {
  const {order, onSubmit, onClose} = props;
  const [data, setData] = React.useState({
    items: order.items,
    date: format(order.delivery_date.$date, 'YYYY-MM-DD'),
    time: order.items[0].config.pick_time, // display the first item's pick_time
  });

  const _onSubmit = React.useCallback(async () => {
    if (!isNotEmpty(data.date)) {
      message.error('請檢查必填欄位');
      return;
    }

    if (!validDateString(data.date)) {
      message.error('請檢查欄位格式');
      return;
    }
    let productsWithNewPickTime = [];
    if (data.time !== '') {
      for (let item of order.items) {
        let new_item = {
          ...item,
          config: {...item.config, pick_time: data.time},
        };
        productsWithNewPickTime.push(new_item);
      }
    }
    await onSubmit({
      delivery_date: {
        $date: Number(moment(`${data.date}T08:00Z`).format('x')), // update to utc timestamp
      },
      items: productsWithNewPickTime,
    });
  }, [data]);

  return (
    <ModalContent title={'編輯訂單資訊'}>
      <StaffEditFormWrapper>
        <FieldRow>
          <Label>
            <span className="asterisk">*</span>自取/取貨日
          </Label>
          <TextInput
            placeholder="YYYY-MM-DD"
            value={data.date}
            onChange={(e) => setData({...data, date: e.target.value})}
          />
        </FieldRow>

        <FieldRow>
          <Label>自取/取貨時間</Label>
          <TextInput
            placeholder="hh:mm-hh:mm"
            value={data.time}
            onChange={(e) => setData({...data, time: e.target.value})}
          />
        </FieldRow>

        <div className="actions">
          <AntButton type="secondary" onClick={onClose}>
            取消
          </AntButton>
          <AntButton
            type={'primary'}
            style={{marginLeft: 10}}
            onClick={_onSubmit}>
            儲存
          </AntButton>
        </div>
        <div className="hint">
          * 按下儲存後，當前修改的其餘表單資訊也會一併更新
        </div>
      </StaffEditFormWrapper>
    </ModalContent>
  );
}

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  ${(props) => props.css}
`;

const Label = styled.label`
  color: #595757;
  margin-right: 20px;
  flex-basis: 100px;
  flex-shrink: 0;

  & > span.asterisk {
    color: var(--color-red);
    margin-right: 3px;
  }
`;

const Hint = styled.span`
  color: #b79d7a;
  font-size: 12px;
  ${(props) => props.css}
`;

const Title = styled.p`
  color: #595757;
  font-size: 16px;
  margin: 20px 0px;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & span.asterisk {
    color: var(--color-red);
    margin-right: 3px;
  }
`;

const StaffEditFormWrapper = styled.div`
  & > .actions {
    display: flex;
    justify-content: flex-end;
  }

  & > .hint {
    color: grey;
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
  }
`;

export default StaffEditCartFormModal;
