import {Form, Input, Select} from 'antd';
import React from 'react';

export const INPUT_TYPES = {
  text: 'text',
  select: 'select',
  textArea: 'text-area',
  password: 'password',
};

const GenericInput = (props) => {
  const {label, name, type, rules, config = {}} = props;
  if (type === INPUT_TYPES.text) {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Input {...props} />
      </Form.Item>
    );
  } else if (type === INPUT_TYPES.textArea) {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Input.TextArea {...props} />
      </Form.Item>
    );
  } else if (type === INPUT_TYPES.password) {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Input.Password {...props} />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Select
          {...props}
          options={[
            {label: props.placeholder, value: '', disabled: true},
            ...props.options,
          ]}
        />
      </Form.Item>
    );
  }
};

export default GenericInput;
