import React from 'react';
import XLSX from 'xlsx';
import {Button} from 'antd';
import {format} from '../utils/date';
import * as AppContext from '../AppContext';
import {
  INVOICE_STATUS_DISPLAY,
  LOGISTICE_TYPE,
  INVOICE_TYPE_DISPLAY,
  LOGISTICE_TYPE_DISPLAY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS_DISPLAY,
} from '../domain/Constants';

const _headers = [
  {label: 'ID', key: 'order_id'},
  {label: '購買人電話', key: 'buyer_phone'},
  {label: '購買人 Email', key: 'buyer_email'},
  {key: 'order_number', label: '國泰訂單付款編號'},
  {key: 'total', label: '金額 (NT)'},
  {key: 'payment_status', label: '付款狀態'},
  {key: 'status', label: '狀態'},
  {key: 'created', label: '訂單成立時間'},
  {key: 'delivery_date', label: '取貨/到貨日'},
  {key: 'logistic_type', label: '物流方式'},
  {key: 'logistic_order_id', label: '物流單號'},
  {key: 'invoice_state', label: '發票狀態'},
  {key: 'invoice_type', label: '發票格式'},
  {key: 'invoice_number', label: '發票號碼'},
  {key: 'gui_number', label: '公司統編'},
];

const _remapOrderDataToDisplayString = (order) => {
  return {
    ...order,
    order_number: order.order_number,
    buyer_phone: order.buyer_phone,
    logistic_order_id: order.logistic_order_id,
    gui_number: order.gui_number,
    payment_status: PAYMENT_STATUS_DISPLAY[order.payment_status],
    status: ORDER_STATUS_DISPLAY[order.status],
    invoice_type: INVOICE_TYPE_DISPLAY[order.invoice_type],
    invoice_number:
      (order.invoice_data && order.invoice_data.invoiceNumber) || '',
    invoice_state: INVOICE_STATUS_DISPLAY[order.invoice_state],
    logistic_type: LOGISTICE_TYPE_DISPLAY[order.logistic_type],
    created: format(order.created, 'YYYY/MM/DD HH:mm'),
    delivery_date: format(order.delivery_date.$date, 'YYYY/MM/DD'),
  };
};

function ExcelOrderExportButton(props) {
  const {
    style = {},
    filterConfigs = {},
    dateRange = {},
    disabled = false,
    filenamePostfix = '',
  } = props;
  const app = React.useContext(AppContext.Context);
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [filename, setFilename] = React.useState('');
  const linkInstance = React.useRef();

  async function _onDownload() {
    setLoading(true);
    let _activeFilterConfig = {...filterConfigs};
    delete _activeFilterConfig.paging;
    let _data = await _multiPageDownload(_activeFilterConfig);
    let _remapToDisplayData = _data.map(_remapOrderDataToDisplayString);
    let _filename = `法朋訂單${dateRange?.from ? `-${dateRange?.from}__` : ''}${
      dateRange?.to ? `${dateRange?.to}` : ''
    }${filenamePostfix ? filenamePostfix : ''}.xlsx`;
    setFilename(_filename);

    const header = _headers.map((h) => h.label);
    const rows = _remapToDisplayData.map((o) => {
      let _result = {};
      for (let h of _headers) {
        _result[h.label] = o[h.key];
      }
      return _result;
    });

    setData(rows);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {header});
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet');
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
    });
    setLink(
      URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      ),
    );
  }

  const _multiPageDownload = React.useCallback(
    async (config) => {
      const PAGE_SIZE = 50;
      let total = 0;
      let _pageIndexs = [];
      let results = [];
      let _config = {
        ...config,
      };
      delete _config.paging;

      try {
        let _firstTry = await app.actions.getOrders({
          ..._config,
          paging: {
            offset: 0,
            limit: 10,
          },
        });
        total = _firstTry.total;
        _pageIndexs = new Array(Math.ceil(total / PAGE_SIZE))
          .fill('')
          .map((i, idx) => idx);
      } catch (err) {
        console.log('err', err);
        alert('fail to get initial page data!');
        return [];
      }

      for (let idx of _pageIndexs) {
        try {
          let _pageData = await app.actions.getOrders({
            ..._config,
            paging: {
              offset: idx * PAGE_SIZE,
              limit: PAGE_SIZE,
            },
          });

          results.push(..._pageData.results);
        } catch (err) {
          //
          alert('fail to get page data!');
          console.log(idx, err);
          return [];
        }
      }

      return results;
    },
    [dateRange],
  );

  React.useEffect(() => {
    if (link && linkInstance && linkInstance.current) {
      let timer = setTimeout(() => {
        linkInstance.current.click();
        setLoading(false);
        setLink(null);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [link, linkInstance]);

  return (
    <div style={style}>
      {link ? (
        <a
          href={link}
          ref={linkInstance}
          download={filename || '法朋訂單報表.xlsx'}>
          <Button loading={loading} onClick={() => 0}>
            下載
          </Button>
        </a>
      ) : (
        <Button disabled={disabled} loading={loading} onClick={_onDownload}>
          下載
        </Button>
      )}
    </div>
  );
}

export default ExcelOrderExportButton;
